import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import http from '@/services'
import Item from '@/Models/Item'

@Component({
  components: {},
})
export default class Paginator extends Vue {
  @Prop({ required: false, default: 5 })
  perPage!: number //cantidad de resultados por página
  @Prop({ required: false })
  url!: string //url base
  @Prop({
    required: false,
    default() {
      return []
    },
  })
  params!: Array<Item> // prop para guardar parametros por url
  total = 0
  current = 1
  rangeBefore = 3
  rangeAfter = 1
  order = ''
  size = ''
  isSimple = false
  isRounded = false
  prevIcon = 'chevron-left'
  nextIcon = 'chevron-right'
  range_perPage = ''
  mounted() {
    this.paginate(this.current)

    this.$set(this, 'range_perPage', this.perPage)
  }

  async paginate(value: number) {
    let url: any
    if (this.total > this.perPage) {
      this.current = value //se guarda el número de la página
    } else {
      this.current = 1
    }
    /* value */
    url = this.url + '?page=' + value + '&page_size=' + this.perPage // se concatena en la url el número de página y la cantidad por página
    try {
      if (this.params.length > 0 || this.params == undefined) {
        //se verifica que el arreglo de parametros contenga al menos 1
        this.params.forEach((element: any) => {
          // se concatenan los parametros en la url
          url += '&' + element.text + '=' + element.value
        })
      }
      const res = await http.get(`${url}`) // se llama al API
      this.total = res.data.count // Se guarda la cantidad total de resultados
      this.$emit('updateList', res.data.results) // se emite la lista
      // console.log(res.data)
    } catch (error: any) {
      //console.error(error.response.data.detail)
      /* this.$buefy.toast.open({
        message: 'Sin conincidencias',
        type: 'is-warning',
      }) */
      this.paginate(1)
    }
  }
  changePerPage() {
    this.$emit('changePerPage', this.range_perPage)
  }
  @Watch('perPage') //variable que quiero saber que cambia
  onPropertyChanged(value: string, oldValue: string) {
    this.paginate(1) //ejecuta la accion cuanndo cambia
  }
}
